import React from 'react';
import ReactDOM from 'react-dom';
import Identicon from '@polkadot/react-identicon';

// Possible themes
// 'polkadot', 'substrate' (default), 'beachball' or 'jdenticon'
const address = 'GZvxvwKGaZyq21BHnrUpdQkpcF4jrCsi23d3c6dvsmp6BF8';
const dotSize = 32;
const textStyle = {
    color: '#ff1864',
    display: 'inline-block',
    fontFamily: '"Space Mono",monospace',
    lineHeight: `${dotSize}px`,
    paddingBottom: '1em',
    paddingLeft: '1em',
    verticalAlign: 'middle'
};

const divStyle = {
    height: `${dotSize}px`
};

ReactDOM.render(
    <div style={divStyle}>
        <Identicon
            value={address}
            size={dotSize}
            theme={'polkadot'}
            isAlternative={true}
        />
      <div style={textStyle}>{address}</div>
    </div>,
    document.getElementById('polkadot-identicon')
);
